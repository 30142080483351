import React, { Component } from 'react';
import { connectComponent } from '../../store';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';

class FilterSection extends Component {

    onFilterChange = (value, action) => {
        let filters = {}
        filters[action.name === 'Author' ? 'cookbookAuthor' : 'cookbookName'] = value ? value.value : null;
        this.props.updateFilters(filters);
        this.props.closeDrawer();
    }

    render(){

        let authorValue = this.props.filters.filters.cookbookAuthor ? {value: this.props.filters.filters.cookbookAuthor, label: this.props.filters.filters.cookbookAuthor} : null;
        let bookValue = this.props.filters.filters.cookbookName ? {value: this.props.filters.filters.cookbookName, label: this.props.filters.filters.cookbookName} : null;

        return (
            <div className="sidebar-section">
                <h3>Filter recipes by...</h3>
                <h5>Author</h5>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    components={makeAnimated()}
                    isDisabled={this.props.recipes.fetching}
                    isLoading={this.props.recipes.fetching}
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.onFilterChange}
                    value={authorValue}
                    name="Author"
                    options={this.props.filters.options.authorOptions}
                />
                <h5>Book</h5>
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    components={makeAnimated()}
                    isDisabled={this.props.recipes.fetching}
                    isLoading={this.props.recipes.fetching}
                    isClearable={true}
                    isSearchable={true}
                    onChange={this.onFilterChange}
                    value={bookValue}
                    name="Book"
                    options={this.props.filters.options.bookOptions}
                />
            </div>
        )
    }
}

export default connectComponent(FilterSection);