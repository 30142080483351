import React, {Component} from 'react'
import Button from 'react-bootstrap/Button';

class SuggestSection extends Component{

    render(){
        return (
            <div className="sidebar-section">
                <h5>Vote for the next cookbook!</h5>
                <div>
                <input name="cookbook" type="radio" value="cookbook1"/> Cookbook Link 1
                </div>
                <div>
                <input name="cookbook" type="radio" value="cookbook2"/> Cookbook Link 2
                </div>
                <div style={{display: 'flex'}}>
                <Button style={{margin: 'auto'}} variant="outline-secondary" onClick={this.vote}>Vote</Button>
                </div>
                
            </div>
        )
    }
}

export default SuggestSection;