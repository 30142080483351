import { deliveryAccessToken, spaceId } from '../config';
import { makeReducer } from './util'
import 'react-image-lightbox/style.css';

export const app = makeReducer(function (action) {
  switch (action.type) {
    case 'LOADED_CLIENT':
      return {authState: action.authState}

  }
}, { authState: 'loading', deliveryAccessToken, spaceId })