import React, { Component } from 'react';
import FilterSection from '../filter-section/FilterSection';
import SearchSection from '../search-section/SearchSection';
import SocialSection from '../social-section/SocialSection';
import SuggestSection from '../suggest-section/SuggestSection';
import './SideDrawer.css';

class SideDrawer extends Component {

    render(){
        let classNames = 'side-drawer';
        if (this.props.show) {
            classNames += ' open';
        }

        return (
            <div className={classNames}>
                <FilterSection closeDrawer={this.props.hide}/>
                {/* <SearchSection closeDrawer={this.props.hide}/> */}
                <SocialSection />
                {/* <SuggestSection closeDrawer={this.props.hide}/> */}
            </div>
        )
    }
}

export default SideDrawer;