import React, { Component } from 'react';
import '../sidedrawer/DrawerToggleButton';
import './Header.css';
import DrawerToggleButton from '../sidedrawer/DrawerToggleButton';

class Header extends Component {
    render() {
      return ( 
        <header className="toolbar">
        <nav className="toolbar__navigation">
          <div />
          <div className="toolbar__logo">
            <a href="/">COOKTHECOOKBOOK</a>
          </div>
          <div className="spacer" />
          <DrawerToggleButton click={this.props.toggleHandler}/>
        </nav>
        <div className="affiliate-disclosure">
          These entries contain affiliate links to products. We may receive a commission for purchases made through these links.
        </div>
      </header>
      )
    }
}

export default Header;