import * as RecipeService from '../services/RecipeService';

export function setAppClientState (authState) {
    return {
      type: 'LOADED_CLIENT',
      authState
    }
  }
  
  export function loadRecipies ({contentTypeId}) {
    return {
      type: 'LOAD_RECIPES',
      payload: RecipeService.loadRecipies(contentTypeId)
    }
  }
  
  export function loadRecipe (id) {
    return {
      type: 'LOAD_RECIPE',
      payload: RecipeService.loadRecipe(id),
      meta: {
        id
      }
    }
  }

  export function updateFilters(filters){
    return {
      type: 'UPDATE_FILTERS',
      payload: filters
    }
  }

  export function doSearch(searchTerm){
    return {
      type: 'SEARCH',
      payload: searchTerm
    }
  }