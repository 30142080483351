import React, { Component } from 'react';
import BeforeAfterSlider from 'react-before-after-slider'

import './SplitImage.css';

class SplitImage extends Component{

      render(){
        return (
            <div className={ `split-image ` + this.props.dir }>
        <BeforeAfterSlider
        before={this.props.src0 + `?w=320`}
        after={this.props.src1 + `?w=320`}
        width={320}
        height={240}
        />
        </div>
        )
    }
}

export default SplitImage