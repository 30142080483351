import React, { Component } from 'react';
import Header from './components/header/Header';
import SideDrawer from './components/sidedrawer/SideDrawer';
import EntryContainer from './components/entry-container/EntryContainer';
import { connectComponent } from './store'
import { initClient } from './services/ContentfulClient'

import './App.css';
import Backdrop from './components/backdrop/Backdrop';
import CookieConsent from 'react-cookie-consent';

class App extends Component {

  state = {
    sideDrawerOpen: false
  }

  componentWillMount () {
    const { spaceId, deliveryAccessToken } = this.props.app

    initClient(spaceId, deliveryAccessToken)
      .then(
        () => this.props.setAppClientState('success'),
        () => this.props.setAppClientState('error')
    )
  }

  drawerToggleClickHander = () => {
    this.setState((prevState) => {
      return {
        sideDrawerOpen: !prevState.sideDrawerOpen
      }
    });
  };

  backDropClickHander = () => {
    this.setState({
      sideDrawerOpen: false
    })
  }

  drawerCloseHandler = () => {
    this.setState({
      sideDrawerOpen: false
    })
  }

  render() {

    let backdrop;

    if (this.state.sideDrawerOpen){
      backdrop = <Backdrop click={this.backDropClickHander}/>
    }
    return (
        <div className="App">
        <Header toggleHandler={this.drawerToggleClickHander}/>
        <SideDrawer show={this.state.sideDrawerOpen} hide={this.drawerCloseHandler}/>
        {backdrop}
        <main style={{marginTop:'96px'}}>
          { this.props.app.authState === 'success' && <EntryContainer/>}
        </main>
        <CookieConsent
        buttonStyle={{ background: "#fff"}}>
          This website uses cookies to enhance user experience. No personal data is stored.
        </CookieConsent>
        
        </div>
    );
  }
}

export default connectComponent(App);
