import React, { Component } from 'react';
import { connectComponent } from '../../store';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

class SearchSection extends Component {

    searchInput
    
    searchRecipes = () => {
        let searchTerm = this.searchInput.value;
        if (searchTerm){
            this.props.doSearch(searchTerm);
            this.props.closeDrawer();
        }
    } 

    render(){

        return (
            <div className="sidebar-section">
                <h3>Search for a recipe...</h3>
                <InputGroup>
                <FormControl ref={input => this.searchInput = input} type="text" placeholder="Search" />
                <InputGroup.Append>
                    <Button variant="outline-secondary" onClick={this.searchRecipes}>Search</Button>
                </InputGroup.Append>
                </InputGroup>
            </div>
        )
    }
}

export default connectComponent(SearchSection);

