import React, { Component, Fragment } from 'react';
import SplitImage from '../split-image/SplitImage';
import ReactMarkdown from 'react-markdown';
import Lightbox from 'react-image-lightbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import "./Entry.css";
import separatorImage from "./separator.png";

class Entry extends Component{

    constructor(props){
        super(props);

        this.state={ 
            photoIndex: 0,
            isOpen: false
        };
    }

    getImages(){
        if (this.props.gallery){
            return this.props.gallery.map(galleryImg => {
                return galleryImg.fields.file.url;
            })
        } else {
            return [];
        }
        
    }

    render(){
        const { photoIndex, isOpen } = this.state;

        var image;
        if (this.props.splitImage !== undefined){
            image = <SplitImage src0={this.props.splitImage[0].fields.file.url}  src1={this.props.splitImage[1].fields.file.url} dir={this.props.dir}/>
        } else {
           image = <img className={`static-image ` + this.props.dir} src={this.props.gallery[0].fields.file.url} alt={this.props.recipeName + ` image`} />
        }

        var blurb = this.props.blurb || "";
        var images = this.getImages();
        let order = this.props.dir === 'left' ? 1 : 12;
        return (
            <Fragment>
            <Row>
                <Col xs={{span: 12, order: 1}} m={{span: 6, order: order}} lg={{span: 5, order: order}} xl={{span: 4, order: order}}>
                    <div className="image-holder" onClick={() => this.setState({isOpen : true})}>
                        {image}
                    </div>
                </Col>
                <Col xs={{span: 12, order:2 }} m={{span: 6, order: 2}} lg={{span: 7, order: 2}} xl={{span: 8, order: 2}}>
                <div className="recipe-title">
                        <h2>{this.props.recipeName}</h2>
                    </div>
                    <div className="book-details">
                        <a href={this.props.affiliateLink}>{this.props.cookbookName}</a> | {this.props.cookbookAuthor}
                    </div>
                    <div>
                        <ReactMarkdown source={blurb} softBreak="br" />
                    </div>
                </Col>

                {isOpen && images.length > 0 && (
                    <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + images.length - 1) % images.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % images.length,
                      })
                    }
                  />
                )}
            </Row>
            <Row>
                <Col xs={12}>
                <img className="separator" src={separatorImage} alt="logo separator"/>
                </Col>
            </Row>
            </Fragment>


            // <div className={`entry ` + this.props.dir}>
            //     <div className="image-holder" onClick={() => this.setState({isOpen : true})}>
            //         {image}
            //     </div>
            //     <div className="recipe-details">
            //         <div className="recipe-title">
            //             <h2>{this.props.recipeName}</h2>
            //         </div>
            //         <div className="book-details">
            //             <a href={this.props.affiliateLink}>{this.props.cookbookName}</a> | {this.props.cookbookAuthor}
            //         </div>
            //         <div>
            //             <ReactMarkdown source={blurb} softBreak="br" />
            //         </div>
                    
            //     </div>
            //     {isOpen && (
            //         <Lightbox
            //         mainSrc={images[photoIndex]}
            //         nextSrc={images[(photoIndex + 1) % images.length]}
            //         prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            //         onCloseRequest={() => this.setState({ isOpen: false })}
            //         onMovePrevRequest={() =>
            //           this.setState({
            //             photoIndex: (photoIndex + images.length - 1) % images.length,
            //           })
            //         }
            //         onMoveNextRequest={() =>
            //           this.setState({
            //             photoIndex: (photoIndex + 1) % images.length,
            //           })
            //         }
            //       />
            //     )}
            // </div>
        )
    }
}

export default Entry;