import { makeReducer } from './util'

import deepExtend from 'deep-extend'

function sortFn(entry1, entry2){
  if (entry1.label === entry2.label) return 0;
  return entry1.label < entry2.label ? -1 : 1;  
}

export const filters = function reduce (state, action) {
  let update = {};
  switch (action.type) {
    case 'LOAD_RECIPES_FULFILLED':
    let authors = [];
    let authorOptions = [];
    let books = [];
    let bookOptions = [];
    action.payload
    .filter(entry => entry.fields.recipeDate && entry.fields.cookbookAuthor && entry.fields.cookbookName)
    .forEach(entry => {
      if (authors.indexOf(entry.fields.cookbookAuthor) === -1){
        authors.push(entry.fields.cookbookAuthor);
        authorOptions.push({value: entry.fields.cookbookAuthor, label: entry.fields.cookbookAuthor});
      } 
      if (books.indexOf(entry.fields.cookbookName) === -1) {
        books.push(entry.fields.cookbookName);
        bookOptions.push({value: entry.fields.cookbookName, label: entry.fields.cookbookName, cookbookAuthor: entry.fields.cookbookAuthor});
      }
    });
    authorOptions.sort(sortFn);
    bookOptions.sort(sortFn);
    update = {
      baseOptions: { authorOptions, bookOptions },
      options: { authorOptions, bookOptions}
    }  
    break;
    case 'UPDATE_FILTERS':
      let restrictedBookOptions = [];
      if (action.payload.cookbookAuthor){
        restrictedBookOptions = state.baseOptions.bookOptions.filter(bookEntry => bookEntry.cookbookAuthor === action.payload.cookbookAuthor);
      } else {
        restrictedBookOptions = state.baseOptions.bookOptions;
      } 
      update = Object.assign({ filters: action.payload, options: { bookOptions: restrictedBookOptions} });
      break;
    // case 'SEARCH':
    //   update = Object.assign({}, {filters: {cookbookAuthor: null, cookbookName: null}});
    //   break;
  }
  return deepExtend({}, state || { options: { authorOptions: [], bookOptions: [] }, filters: {cookbookAuthor: null, cookbookName: null} }, update || {})
}