import React, { Component } from 'react';

import './SocialSection.css';

class SocialSection extends Component{

    render(){
        return(
            <div className="social-section">
            <span>
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/cookthecookbook" className="instagram social-button">&nbsp;</a>
            </span>
            <span>
            
                <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/cookthecookbook" className="twitter social-button">&nbsp;</a>
                </span>
                <span>
            
                <a target="_blank" rel="noopener noreferrer" href="https://pinterest.co.uk/cookthecookbook" className="pinterest social-button">&nbsp;</a>
                </span>
            </div>
        )
    }
}

export default SocialSection;