import React, { Component } from 'react';
import { connectComponent } from '../../store';
import Entry  from '../entry/Entry';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './EntryContainer.css';

class EntryContainer extends Component{

    mapEntries(){
        let count = 1;
        var entryArray = [];
        let filters = this.props.filters.filters;
        this.props.recipes.entries
        .filter(recipe => {
            let include = false;
            if (filters['cookbookAuthor'] != null){
                 include |= recipe.fields['cookbookAuthor'] === filters['cookbookAuthor']; 
            }
            if (filters['cookbookName'] != null){
                include |= recipe.fields['cookbookName'] === filters['cookbookName'];
            }
            return include |= (filters['cookbookAuthor'] === null && filters['cookbookName'] == null);
        })
        .forEach(recipe => {
                count ++;
                entryArray.push(<Entry key={recipe.sys.id} {...recipe.fields} dir={count % 2 === 0 ? `left` : `right`} />);
        });
        return entryArray;
    }

    componentWillMount () {
        this.props.loadRecipies({contentTypeId: 'recipeEntry'})
    }

    render(){
        if (Object.keys(this.props.recipes.entries).length > 0) {
            return (
                <div className="entry-container container">
                    { this.mapEntries() }
                </div>
            )
        } else {
            return (
                <div className="entry-container-no-entries container" /> 
                
            )
        }
    }
}

export default connectComponent(EntryContainer);