import { createClient } from 'contentful';

let client
let authorized

export function initClient (spaceId, accessToken) {
  client = createClient({
    space: spaceId,
    accessToken
  })
  return client.getSpace()
    .then((space) => {
      authorized = true
      return space
    })
}

export function getClient () {
  return authorized && client
}
