import { makeReducer } from './util'

export const recipes = makeReducer(function (action) {
  switch (action.type) {
    case 'LOAD_RECIPES_PENDING':
      return { fetching: true }
    case 'LOAD_RECIPES_FULFILLED':
    return {
      fetching: false,
      entries: action.payload
      .filter(entry => entry.fields.recipeDate)
      .sort((entry1, entry2) => {
        if (!entry1.fields.recipeDate || !entry2.fields.recipeDate) return 0;
        return new Date(entry2.fields.recipeDate).getTime() - new Date(entry1.fields.recipeDate).getTime();
      })
    }  
    case 'LOAD_RECIPES_REJECTED':
      return { error: true, fetching: false }

    case 'LOAD_RECIPE_PENDING':
      return {
        entries: {
          [ action.meta.id ]: {
            fetching: true
          }
        }
      }
    case 'LOAD_RECIPE_FULFILLED':
      action.payload.fetching = false

      action.payload.fields.images.forEach(image => {
        image.thumbnail = `${image.fields.photo.fields.file.url}?w=120`
      })

      return {
        entries: {
          [ action.meta.id ]: action.payload
        }
      }
    case 'LOAD_RECIPE_REJECTED':
      return {
        entries: {
          [ action.meta.id ]: {
            error: true,
            fetching: false
          }
        }
      }
    default:
      return {}
  }
}, { entries: {} })